import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import bgTranslations from './CookieConsentBG.json'
import enTranslations from './CookieConsentEN.json'

/**
 * @see https://cookieconsent.orestbida.com/essential/getting-started.html
 */
export default class OrestbidaCookieConsent {

    static show() {
        const isEn = window.location.href.includes("/en/");
        let translations = bgTranslations
        if (isEn) {
            translations = enTranslations
        }

        CookieConsent.run({

            root: 'body',
            autoShow: true,
            disablePageInteraction: false,
            hideFromBots: true,
            mode: 'opt-in',
            revision: 0,
            page_scripts: true,

            /**
             * @see https://cookieconsent.orestbida.com/reference/configuration-reference.html#cookie
             */
            cookie: {
                name: 'cookie_consent', // use json here ...
            },

            /**
             * @see https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
             */
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    equalWeightButtons: true,
                    flipButtons: false
                },
                preferencesModal: {
                    layout: 'bar',
                    position: 'left',
                    equalWeightButtons: true,
                    flipButtons: false
                }
            },
            categories: {
                autoClearCookies: true,
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true,  // this category cannot be disabled
                    cookies: [
                        {
                            name: 'TSSESSID',
                        },
                        {
                            name: 'REMEMBERME',
                        },
                        {
                            name: 'TSFP',
                        },
                        {
                            name: 'TSCP',
                        },
                        {
                            name: 'cookie_consent',
                        },
                        {
                            name: 'configuratorDataBracelet',
                        },
                        {
                            name: 'configuratorDataWatch',
                        },
                        {
                            name: 'cartId',
                        },
                        {
                            name: 'currencyId',
                        },
                        {
                            name: 'favorite_products',
                        },
                        {
                            name: 'last-viewed-products',
                        },
                        {
                            name: /^(_hjSession).*/,
                        }
                    ],
                },
                analytics: {
                    enabled: false,
                    autoClear: {
                        cookies: [
                            {
                                name: /^(_ga|_gcl_au|_gid|_cs).*/
                            },
                        ]
                    },
                    services: {
                        ga: {
                            label: translations.labels.analytics,
                            cookies: [
                                {
                                    name: /^(_ga|_gcl_au|_gid|_cs).*/
                                },
                            ]
                        }
                    },
                },

                ads: {
                    enabled: false,
                    autoClear: {
                        cookies: [
                            {
                                name: /^(_gcl_au).*/
                            },
                        ]
                    },
                    services: {
                        ga: {
                            label: translations.labels.ads,
                            cookies: [
                                {
                                    name: /^(_gcl_au).*/
                                },
                            ]
                        }
                    }
                }
            },
            language: {
                default: 'en',
                autoDetect: 'browser',
                translations: {
                    en: translations.language
                }
            }
        });
    }
}